import { styled } from '../../stitches.config';
import { BaseIconStyle, BaseIconPathStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function FurnitureIcon({ size }: IconBaseType) {
  return (
    <StyledSvg
      width="59"
      height="38"
      viewBox="0 0 59 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      size={size}
    >
      <g clip-path="url(#clip0_1921_233)">
        <StyledPath
          d="M43.7528 5.51506C43.7528 5.51506 43.7839 5.51506 43.7995 5.51506C44.1653 5.51506 44.4689 5.23542 44.5 4.86257L44.7258 1.52242C44.7491 1.13403 44.4611 0.800016 44.0719 0.776713C43.6983 0.753409 43.3481 1.04082 43.3247 1.42921L43.099 4.76935C43.0756 5.15774 43.3636 5.49176 43.7528 5.51506Z"
          fill="#181818"
        />
        <StyledPath
          d="M14.8358 5.47622C14.8358 5.47622 14.8669 5.47622 14.8825 5.47622C15.2717 5.45292 15.5597 5.1189 15.5363 4.73051L15.3106 1.42921C15.2872 1.04082 14.937 0.753409 14.5634 0.776713C14.1742 0.800016 13.8862 1.13403 13.9095 1.52242L14.1353 4.82373C14.1586 5.19658 14.4699 5.47622 14.8358 5.47622Z"
          fill="#181818"
        />
        <StyledPath
          d="M58.999 0.768971C59.0302 0.380582 58.7499 0.0465673 58.3685 0.00772833C57.9871 -0.0233428 57.6447 0.256297 57.6058 0.636919L55.8778 19.5204H43.5407C42.0462 19.5204 40.8242 20.7322 40.8242 22.2314V37.3009C40.8242 37.6893 41.1356 38 41.5247 38C41.9139 38 42.2253 37.6893 42.2253 37.3009V22.2314C42.2253 21.509 42.8168 20.9186 43.5407 20.9186H55.8155V37.3009C55.8155 37.6893 56.1269 38 56.516 38C56.9052 38 57.2166 37.6893 57.2166 37.3009V20.2583L58.999 0.768971Z"
          fill="#181818"
        />
        <StyledPath
          d="M15.4665 19.5281H3.12943L1.40146 0.63685C1.36254 0.256228 1.02006 -0.0234121 0.638666 -0.000108702C0.249484 0.0387302 -0.0307267 0.380513 0.000407901 0.768902L1.79065 20.2583V37.3008C1.79065 37.6892 2.10199 37.9999 2.49117 37.9999C2.88036 37.9999 3.1917 37.6892 3.1917 37.3008V20.9263H15.4665C16.1904 20.9263 16.7819 21.5167 16.7819 22.2391V37.3086C16.7819 37.6969 17.0933 38.0077 17.4825 38.0077C17.8716 38.0077 18.183 37.6969 18.183 37.3086V22.2313C18.183 20.7399 16.9687 19.5203 15.4665 19.5203V19.5281Z"
          fill="#181818"
        />
        <StyledPath
          d="M35.5626 36.6018H30.1997V8.96398H47.4715C47.8607 8.96398 48.1721 8.65327 48.1721 8.26488C48.1721 7.87649 47.8607 7.56578 47.4715 7.56578H41.9296L42.3966 0.745663C42.4122 0.551468 42.3421 0.365041 42.2098 0.217453C42.0775 0.0698653 41.8907 -0.0078125 41.6961 -0.0078125H35.4614C35.2668 -0.0078125 35.08 0.0698653 34.9477 0.217453C34.8154 0.365041 34.7453 0.551468 34.7609 0.745663L35.2279 7.56578H23.4123L23.8793 0.745663C23.8949 0.551468 23.8249 0.365041 23.6925 0.217453C23.5602 0.0698653 23.3734 -0.0078125 23.1788 -0.0078125H16.9441C16.7495 -0.0078125 16.5627 0.0698653 16.4304 0.217453C16.2981 0.365041 16.228 0.551468 16.2436 0.745663L16.7106 7.56578H11.5267C11.1375 7.56578 10.8262 7.87649 10.8262 8.26488C10.8262 8.65327 11.1375 8.96398 11.5267 8.96398H28.7986V36.6018H23.4357C23.0465 36.6018 22.7351 36.9125 22.7351 37.3009C22.7351 37.6892 23.0465 38 23.4357 38H35.5626C35.9518 38 36.2631 37.6892 36.2631 37.3009C36.2631 36.9125 35.9518 36.6018 35.5626 36.6018ZM36.2086 1.39816H40.9411L40.5208 7.53471C40.5208 7.53471 40.5208 7.55024 40.5208 7.56578H36.6212C36.6212 7.56578 36.6212 7.55024 36.6212 7.53471L36.2008 1.39816H36.2086ZM17.6913 1.39816H22.4238L22.0035 7.53471C22.0035 7.53471 22.0035 7.55024 22.0035 7.56578H18.1039C18.1039 7.56578 18.1039 7.55024 18.1039 7.53471L17.6836 1.39816H17.6913Z"
          fill="#181818"
        />
      </g>
      <defs>
        <clipPath id="clip0_1921_233">
          <rect width="59" height="38" fill="white" />
        </clipPath>
      </defs>
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default FurnitureIcon;
